import { useEventHandler } from "./useEventHandler";
import { useVisibleEffect } from "./useVisibilityChange";
export const useInterval = (interval, cb)=>{
    let fn = useEventHandler(cb);
    useVisibleEffect(()=>intervalEffect(interval, fn));
};
export function intervalEffect(interval, fn) {
    let start = Date.now(), handler = setInterval(()=>fn(Date.now() - start), interval);
    return ()=>clearInterval(handler);
}
